import axios from 'axios'

export default {
	namespaced: true,

	state() {
        return {
            experts: []
        }
	},
    getters: {
        experts(state) {
            return state.experts
        }
    },
	mutations: {
        SET_EXPERTS(state, experts) {
            state.experts = experts
        }
    },
	actions: {
        async loadExperts({commit}) {
            let response = await axios.get('experts')
            commit('SET_EXPERTS', response.data.users)
        },
        async loadExpert(_, username) {
            let response = await axios.get(`profile/${username}`)

            return response.data
        }
	}
}
