<template>
  <v-form @submit="saveOrganization">
        <ion-item>
          <ion-label position="stacked"><span>*</span> Name</ion-label>
          <v-field name="Name" v-slot="{field}" rules="required" v-model="organizationData.name">
            <ion-input  v-bind="field" name="name" type="text" placeholder="Organization name"></ion-input>
          </v-field>
          <v-error-message class="error" name="Name"/>
        </ion-item>

        <ion-item>
          <v-field name="Regcode" v-slot="{field}" rules="required" v-model="organizationData.regcode">
            <ion-label position="floating">Regcode</ion-label>
            <ion-input type="number" v-bind="field"></ion-input>
          </v-field>
          <v-error-message name="Regcode" class="error"/>
        </ion-item>

        <ion-item>
          <v-field name="Address" v-slot="{field}" rules="required" v-model="organizationData.address">
            <ion-label position="floating">Address</ion-label>
            <ion-input type="text" v-bind="field"></ion-input>
          </v-field>
          <v-error-message name="Address" class="error"/>
        </ion-item>

        <ion-item>
          <v-field name="Country" v-slot="{field}" rules="required" v-model="organizationData.country">
            <ion-label position="floating">Country</ion-label>
            <ion-input type="text" v-bind="field"></ion-input>
          </v-field>
          <v-error-message name="Country" class="error"/>
        </ion-item>

        <ion-item>
          <v-field name="Website" v-slot="{field}" v-model="organizationData.website">
            <ion-label position="floating">Website</ion-label>
            <ion-input type="url" v-bind="field"></ion-input>
          </v-field>
          <v-error-message name="Website" class="error"/>
        </ion-item>

        <ion-item>
          <v-field name="Business goals" v-slot="{field}" v-model="organizationData.business_goals">
            <ion-label position="floating">Business goals</ion-label>
            <ion-textarea rows="4" v-bind="field"></ion-textarea>
          </v-field>

        </ion-item>

        <ion-item>
          <v-field name="Main target groups" v-slot="{field}" v-model="organizationData.target_groups">
            <ion-label position="floating">Main target groups</ion-label>
            <ion-textarea rows="4" v-bind="field"></ion-textarea>
          </v-field>
        </ion-item>

        <ion-button type="submit">Save</ion-button>

  </v-form>
</template>

<script>
import {
  defineComponent
} from 'vue';
import axios from 'axios'
import {
  IonButton,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  toastController
} from '@ionic/vue';

import * as V from 'vee-validate/dist/vee-validate'
import {
  defineRule
} from 'vee-validate/dist/vee-validate'
import {
  email,
  required
} from '@vee-validate/rules'
import {checkmarkOutline} from "ionicons/icons";

export default defineComponent({
  name: 'OrganizationForm',
  components: {
    IonButton,
    IonItem,
    IonInput,
    IonTextarea,
    IonLabel,
    VForm: V.Form,
    VField: V.Field,
    VErrorMessage: V.ErrorMessage
  },
  props: {
    organization: null,
  },
  emits: ['save-organization'],
  setup() {
    defineRule('email', email)
    defineRule('required', required)

    return {
      email,
      required
    }
  },
  data() {
    return {
      organizationData: null
    }
  },
  created() {
    if (!this.organization) {
      this.organizationData = {
        slug: null,
        name:null,
        regcode: null,
        address: null,
        country: null,
        website: null,
        business_goals: null,
        target_groups: null
      }
    } else {
      this.organizationData = this.organization
    }

  },
  computed: {

  },
  methods: {
    saveOrganization() {
      this.$emit('save-organization', this.organizationData)
    },



    async addOrganization(data) {

      let info = {
        'name' : data['Name'],
        'regcode' : data['Regcode'],
        'address' : data['Address'],
        'country' : data['Country'],
        'website' : data['Website'],
        'business_goals' : data['Business goals'],
        'target_groups' : data['Main target groups'],
      }

      let toastMsg = '';

      await axios.post('organizations', info).then(() => {
        toastMsg = {
          message: 'Organization added!',
          duration: 2000,
          position: 'top',
          icon: checkmarkOutline,
          color: 'success'
        }
        this.$refs.modal.$el.dismiss(name, 'confirm');
      }).catch((error) => {
        toastMsg = {
          header: 'Error',
          message: 'Something went wrong',
          duration: 3000,
          position: 'top',
          color: 'danger'
        }
        this.errors = error.response.data
      })

      let toast = await toastController.create(toastMsg);
      return await toast.present()

    },

  }
});
</script>
<style scoped>
ion-content {
  height: calc(100% - 56px);
  overflow: auto;
}
</style>