<template>
  <base-layout page-title="All experts" >
    <ion-searchbar placeholder="Search expert with skill" v-model="search"></ion-searchbar>
    <div v-if="search">
      <ion-chip @click="setSearch('all')" color="warning">Clear search</ion-chip>
      <span v-for="(skill,idx) in allSkills" :key="idx">
        <ion-chip @click="setSearch(skill)">{{ skill }}</ion-chip>
      </span>
    </div>
    <p class="ion-padding-horizontal" v-if="search.length">Searching experts with <strong>{{ search }}</strong> skill for your next awesome project!</p>
    <ion-grid>
      <ion-row>
        <ion-col :routerLink="'/experts/' + expert.username" size-xs="12" size-md="6" size-xl="4"
                 v-for="expert in filteredExperts" :key="expert.id">
          <ExpertItem :expert="expert"/>
        </ion-col>
      </ion-row>
    </ion-grid>

  </base-layout>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSearchbar,
    IonChip,

} from '@ionic/vue';

import {
  defineComponent
} from 'vue';

import ExpertItem from '@/components/experts/ExpertItem.vue';

export default defineComponent({
  name: 'ExpertsList',
  data() {
    return {
      allSkills: [],
      search: '',
    }
  },
  created() {
    this.$store.dispatch('experts/loadExperts')
  },
  computed: {
    filteredExperts() {
      let searchValues = this.search ? [this.search.toLocaleLowerCase()] : []
      let allExperts = this.$store.getters['experts/experts']
      let that = this
      return allExperts.filter(expert => {
        let expertSkills = []
        JSON.parse(JSON.stringify(expert.skills)).forEach(function(skill) {
          expertSkills.push(skill.title.toLocaleLowerCase())
          if (!that.allSkills.includes(skill.title.toLocaleLowerCase())) {
            that.allSkills.push(skill.title.toLocaleLowerCase())
          }
        })

        //console.log(searchValues)

        if (searchValues.every(item => expertSkills.includes(item))) {
          return true
        }

        /*searchValues.every((item) => {
          console.log(item)
        })

        if(expertSkills.indexOf( this.search.toLowerCase()) !== -1 ) {
          return true;
        }*/

        if (searchValues.length < 1) {
          return true;
        }
        return false
      })
    }

  },
  methods: {
    setSearch(skill) {
      this.search = (skill == 'all') ? '' : skill
    },
  },
  components: {
    ExpertItem,
    IonGrid,
    IonRow,
    IonCol,
    IonSearchbar,
    IonChip

  },
});
</script>
