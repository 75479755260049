import { createApp } from 'vue'

import App from './App.vue'
import axios from 'axios'
import {store} from './store'
import router from './router'

require('@/store/subscriber')

axios.defaults.baseURL = process.env.VUE_APP_API_URL

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

//import { ValidationProvider } from 'vee-validate';
//import { required } from 'vee-validate/dist/rules';

// extend('required', {
//   ...required,
//   message: 'This field is required'
// });

/* Theme variables */
import './theme/variables.css';

import './theme/main.css';

import BaseLayout from './components/base/BaseLayout.vue';

//import {wysiwyg} from "vue-wysiwyg"
//import '../node_modules/vue-wysiwyg/dist/vueWysiwyg.css'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

store.dispatch('auth/attempt', localStorage.getItem('token')).then( () => {
    const app = createApp(App)
      .use(IonicVue)
      .use(router)
      .use(store);

    app.component('base-layout', BaseLayout);

    const QuillGlobalOptions = {
        theme: 'snow'
    }
    QuillEditor.props.globalOptions.default = () => QuillGlobalOptions
    app.component('QuillEditor', QuillEditor)

    router.isReady().then(() => {
      app.mount('#app');
    });
})

// legacy
localStorage.removeItem('accessToken')
localStorage.removeItem('userData')


