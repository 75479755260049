<template>
  <div class="ion-padding">

    <div v-for="organization in userOrganizations" :key="organization.slug">

      <ion-card v-if="organization.pivot && organization.pivot.can_edit">

        <ion-card-content>
          <OrganizationForm :organization="organization"  @save-organization="saveOrganization" />
        </ion-card-content>

        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button v-if="organization.slug" fill="outline" @click="viewExperts(organization.id)">View {{ organization.name }} experts</ion-button>
            </ion-col>
            <ion-col class="ion-text-right">
              <ion-button type="button" color="danger" @click="deleteOrganization(organization.id)">Delete organization</ion-button>
            </ion-col>
          </ion-row>

        </ion-grid>



      </ion-card>
      <ion-card v-else>
        <ion-card-header>
          <ion-card-subtitle>Reg.code: {{ organization.regcode }}</ion-card-subtitle>
          <ion-card-title>{{ organization.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>{{ organization.website }}</p>
          <p>{{ organization.address }} - {{ organization.country }}</p>
        </ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button v-if="organization.slug" @click="viewExperts(organization.id)">View {{ organization.name }} experts</ion-button>

            </ion-col>
            <ion-col class="ion-text-right">
              <ion-button @click="leave(organization)" type="button" color="danger" fill="outline">Leave from {{ organization.name }}</ion-button>

            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>

      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-md="6" size-xl="4"
                   v-for="(expert, idx) in organizationExperts[organization.id]" :key="idx">
            <ion-card>
              <ion-card-header>
                <ion-card-subtitle>{{ expert.pivot.role }}</ion-card-subtitle>
                <ion-card-title>
                  <ion-icon :icon="personCircleOutline"
                            :color="(expert.status === 'active') ? 'success' : 'medium'">
                  </ion-icon>
                  {{ expert.name }}
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-button  type="button" expand="block" :routerLink="'/experts/' + expert.username">View expert</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>

        </ion-row>
      </ion-grid>


    </div>

    <ion-fab slot="fixed" class="bottom-right">
      <ion-fab-button ref="organization_form_modal"
                      id="organization_form_modal">
        <ion-icon :icon="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <OrganizationAdd />

  </div>
</template>

<script>

import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonCardContent,
  toastController,
  IonFab,
  IonFabButton,
  IonIcon, alertController,
} from '@ionic/vue';

import {
  checkmarkOutline,
  addOutline,
  personCircleOutline
} from 'ionicons/icons';

import {
  defineComponent
} from 'vue';
import axios from "axios";

import OrganizationAdd from "@/components/organizations/OrganizationAdd";
import OrganizationForm from "@/components/organizations/OrganizationForm";

export default defineComponent({
  name: 'ProfileOrganizations',
  emits: [],
  props: {
    organizations: Object,
  },

  data() {
    return {
      addOutline,
      personCircleOutline,
      organizationExperts: []
    }

  },
  created() {

  },
  computed: {
    userOrganizations() {
      return this.$store.getters['auth/organizations']
    }
  },
  methods: {
    async saveOrganization(data) {
      let toastMsg = {}
      await this.$store.dispatch('auth/saveOrganization', data).then(() => {
        toastMsg = {
          header: data.name,
          icon: checkmarkOutline,
          message: 'info saved',
          duration: 3000,
          position: 'top',
          color: 'success'
        }
      })

      let toast = await toastController.create(toastMsg);
      return await toast.present()
    },
    async deleteOrganization(id) {

      const alert = await alertController.create({
        header: 'Delete organization?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {

            },
          },
          {
            text: 'Confirm',
            role: 'confirm',
            handler: () => {
              axios.post(`organizations/delete`, {id: id}).then(() => {
                this.$store.commit('auth/REMOVE_ORGANIZATION', id)
              })
            },
          },
        ],
      });

      await alert.present();

    },

    async viewExperts(id) {
      if (!this.organizationExperts[id]) {
        await axios.get('organization/' + id).then((res) => {
          this.organizationExperts[id] = res.data
        })
      }
    },
    async leave(organization) {
      const alert = await alertController.create({
        header: 'Leave organization',
        message: 'Really leave from ' + organization.name + '?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Confirm',
            role: 'confirm',
            handler: () => {
              axios.post(`user/organizations/leave`, {id: organization.id}).then(() => {
                this.$store.commit('auth/REMOVE_ORGANIZATION', organization.id)
              })
            },
          },
        ],
      });

      await alert.present();
    }
  },
  components: {
    OrganizationForm,
    OrganizationAdd,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonFab,
    IonFabButton,
    IonIcon,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
  },
});
</script>
<style lang="css" scoped>
.bottom-right {
  position: fixed;
  bottom: 40px;
  right: 40px;
}
</style>
