<template>
  <div v-if="expert" :detail="false">
    <ion-card class="card-item">
      <ion-card-header>
        <ion-card-subtitle v-if="expert.userinfo">{{ expert.userinfo.oneliner }}</ion-card-subtitle>
        <ion-card-title >
          <ion-item lines="none" class="ion-no-padding">
            <ion-avatar slot="start" v-if="expert.userinfo">
              <img :alt="expert.name" :src="expert.userinfo.avatar" v-if="expert.userinfo.avatar"/>
            </ion-avatar>
            <h4>{{expert.name }}</h4>
            <template v-if="expert.userinfo">
              <ion-badge slot="end" v-if="expert.userinfo.hourly_rate" color="success">from: {{ expert.userinfo.hourly_rate }}€</ion-badge>
            </template>
          </ion-item>
        </ion-card-title>
      </ion-card-header>

      <ion-card-content>

          <div v-if="expert.skills" class="ion-margin-bottom">
            <ion-chip v-for="skill in expert.skills" :key="skill.id" >
              <ion-label>{{ skill.title }}</ion-label>
            </ion-chip>
          </div>

        <ion-button color="primary" expand="block">View expert</ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonBadge,
  IonButton,
  IonChip,
  IonItem,
    IonAvatar
} from '@ionic/vue';
import {
  star
} from 'ionicons/icons';
import {
  defineComponent
} from 'vue';

export default defineComponent({
  name: 'ExpertItem',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonBadge,
    IonButton,
    IonChip,
    IonItem,
    IonAvatar
  },
  props: {
    expert: Object,
  },
  methods: {},
  data() {
    return {
      star
    }
  }
});
</script>
