<template>
  <base-layout page-title="Login">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col size-xs="12" size-md="6" size-lg="5" offset-lg="1" size-xl="6">
          <ion-img :src="loginImg"></ion-img>
          <h3 class="ion-margin-top ion-padding-top">Intelligent platform for digital professionals to create successful project teams.</h3>

        </ion-col>
        <ion-col size-xs="12" size-md="5" size-lg="4" offset-md="1" size-xl="3">
          <ion-img :src="logoImg" class="logo mt-4 mb-5"></ion-img>
          <p>Please sign-in to your account and start your next project with us!</p>
          <v-form @submit="submit">

            <ion-item>
              <v-field name="email" v-slot="{field}" v-model="form.email" rules="email|required">
                <ion-label position="floating">E-mail</ion-label>
                <ion-input type="email" v-bind="field"></ion-input>
              </v-field>
              <v-error-message name="email" class="error" />
            </ion-item>

            <ion-item>
              <v-field name="password" v-slot="{field}" v-model="form.password" rules="required">
                <ion-label position="floating">Password</ion-label>
                <ion-input type="password" v-bind="field"></ion-input>
              </v-field>
              <v-error-message name="password" class="error" />

            </ion-item>

            <ion-button type="sumbit" expand="full" color="primary" class="ion-margin-top">Login</ion-button>

          </v-form>

          <h3 class="ion-text-center ion-padding-top">or login with</h3>

          <ion-buttons class="ion-justify-content-center">
            <ion-button :href="socialLoginUrl + 'google'" color="dark">
              <ion-icon :icon="logoGoogle" size="large"></ion-icon>
            </ion-button>

            <ion-button :href="socialLoginUrl + 'facebook'" color="dark">
              <ion-icon :icon="logoFacebook" size="large"></ion-icon>
            </ion-button>

            <ion-button :href="socialLoginUrl + 'github'" color="dark">
              <ion-icon :icon="logoGithub" size="large"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-item lines="none"  router-link="/forgot-password">
            <p slot="end">Forgot password?</p>
          </ion-item>


        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
    IonIcon,
    toastController,
} from '@ionic/vue';

import {
    logoFacebook,
    logoGithub,
    logoGoogle,
  informationCircle,
    checkmark,
} from 'ionicons/icons';

import * as V from 'vee-validate/dist/vee-validate'
import {
  defineRule
} from 'vee-validate/dist/vee-validate'
import {
  email,
  required
} from '@vee-validate/rules'


export default defineComponent({
  name: 'LoginPage',
  setup() {
    defineRule('email', email)
    defineRule('required', required)

    return {
      email,
      required
    }
  },
  data() {
    return {
      logoFacebook,
      logoGithub,
      logoGoogle,

      form: {
        email: '',
        password: ''
      },
      socialLoginUrl: process.env.VUE_APP_API_URL + 'user/login/',
      logoImg: require('@/assets/img/logo.svg'),
      loginImg: require('@/assets/img/login.svg'),
    }
  },
  mounted() {

    if (this.authenticated) {
      this.$router.replace({
        name: 'Home'
      })
    }
    const token = this.$route.params.token;

    if (token) {
      this.socialLogin(token).then(() => {
        if (this.authenticated) {
          //let name = this.user.name ? this.user.name : ''
          this.notify( `You have successfully logged in. Now you can start to explore!`)
          this.$router.replace({
            name: 'Home'
          })
        } else {
          this.notify( 'Sorry, we could not log you in. Invalid token.', 'danger')
          this.$router.replace({
            name: 'Login'
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      socialLogin: 'auth/socialLogin'
    }),
    async notify(text, type) {
      let toast = await toastController.create({
        message: text,
        duration: 3000,
        position: 'top',
        icon: (type == 'danger') ? informationCircle : checkmark,
        color: type ? type : 'success'
      });
      return await toast.present()
    },
    submit() {

      this.login(this.form).then((res) => {
        if (res && res.errors) {
          this.notify('Login failed', 'danger')
        } else {
          this.notify( `You have successfully logged in as ${this.user.name}. Now you can start to explore!`)
          this.$router.replace({
            name: 'Home'
          })
        }

      })
    }
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonImg,
    IonIcon,
    VForm: V.Form,
    VField: V.Field,
    VErrorMessage: V.ErrorMessage
  },
});
</script>

<style scoped>
.logo {
  max-width: unset;
  width: 200px;
}
.error {
  color: red;
}
</style>
