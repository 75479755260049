<template>
  <base-layout page-title="Invite expert">

    <div v-if="1 == 1">
      <ion-row>
        <ion-col size-xs="12" size-md="8" size-lg="6" class="ion-padding">

          <div v-if="errors">
            <p class="error">{{ errors.message }}</p>
            <!--<div v-for="error, idx in errors" :key="idx">
              {{ idx }}
              {{ error }}
            </div>-->
          </div>
          <v-form @submit="sendInvite">
            <ion-item>
              <v-field name="Invitee email" v-slot="{field}" rules="email|required" v-model="invitation.email">
                <ion-label position="floating">* Enter expert e-mail</ion-label>
                <ion-input type="invitee_email" v-bind="field"></ion-input>
              </v-field>
              <v-error-message name="Invitee email" class="error"/>
            </ion-item>

            <ion-item>
              <ion-label position="floating">Organization</ion-label>
              <ion-select interface="popover" placeholder="Choose organization"
                          v-model="invitation.organization">
                <ion-select-option v-for="organization in user.organizations" :key="organization.id"
                                   :value="organization.id">
                  {{ organization.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <!--<ProjectInviteRole
                v-model:role="invitation.role"
                v-model:project_id="invitation.project_id"
            /> -->


            <ion-item>
              <v-field name="comment" v-slot="{field}" v-model="invitation.comment">
                <ion-label position="floating">Comment</ion-label>
                <ion-textarea v-bind="field"></ion-textarea>
              </v-field>
              <v-error-message name="comment" class="error"/>
            </ion-item>

            <ion-button type="submit">Send invite</ion-button>
          </v-form>
        </ion-col>

        <ion-col size-xs="12" size-md="6" size-lg="4" class="ion-padding">
          <ion-text>
            <h2>Already working together on projects with some awesome experts?</h2>
            <p>KRATT users can invite unlimited number of experts to join KRATT.</p>
            <p v-if="invitationUrl">
              <strong>Last invitation url</strong>: <a :href="invitationUrl" target="_blank">{{ invitationUrl }}</a>
            </p>
          </ion-text>
        </ion-col>

      </ion-row>

      <div class="ion-padding" v-if="user.invites">

        <h3 class="ion-margin-bottom">My invitations</h3>

        <vue-good-table
            :columns="columns"
            :rows="user.invites"
            :sort-options="{
            enabled: true,
            initialSortBy: {field: 'created_at', type: 'desc'}
          }"
            :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 25,
            position: 'top',
            perPageDropdown: [10, 25, 50, 100],
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          }"
        >
          <template #table-row="props">

            <ion-item lines="none" v-if="props.column.field == 'invitee_email'" >
              <ion-icon slot="start" :icon="personCircleOutline" :color="(props.row.status == 'ACCEPTED') ? 'success' : 'medium'"></ion-icon>
              {{props.formattedRow[props.column.field]}}
            </ion-item>

            <ion-item lines="none" v-else>
              {{props.formattedRow[props.column.field]}}
            </ion-item>
          </template>
        </vue-good-table>
      </div>
      <p class="ion-padding" v-else>You haven't invited anyone yet. Time to make the first step!</p>
    </div>
    <div v-else class="ion-padding">

      <h3>Sorry, this section is only for agencies!</h3>
      <p>KRATT users can invite unlimited number of experts to join KRATT.</p>
      <ion-button color="success">Claim you agency role</ion-button>
    </div>


  </base-layout>
</template>

<script>

import {
  defineComponent
} from 'vue';

import * as V from 'vee-validate/dist/vee-validate'
import {
  defineRule
} from 'vee-validate/dist/vee-validate'
import {
  email,
  required
} from '@vee-validate/rules'

import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonTextarea,
  IonSelect,
  toastController
} from '@ionic/vue';

import { VueGoodTable } from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'

//import ProjectInviteRole from "@/components/projects/ProjectInviteRole";

import {
  mapGetters,
} from 'vuex'
import axios from "axios";
import {checkmarkDone, personCircleOutline} from "ionicons/icons";

export default defineComponent({
  name: 'ExpertInvite',
  data() {
    return {
      personCircleOutline,
      invitation: {
        email: null,
        project_id: null,
        organization: null,
        role: null,
        comment: null
      },
      invitationUrl: null,
      errors: [],
      columns: [
        {
          label: 'E-mail',
          field: 'invitee_email',
        },

        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Comment',
          field: 'comment',
        },
        {
          label: 'Sent',
          field: 'created_at',

        },

      ],

    }
  },
  setup() {
    defineRule('email', email)
    defineRule('required', required)

    return {
      email,
      required
    }
  },
  mounted() {
    this.$store.dispatch('auth/loadUserInvites')
  },
  created() {
    if (this.projects.length < 1) {
      this.$store.dispatch('projects/loadProjects')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      projects: 'projects/projects',
    })
  },

  methods: {
    async sendInvite() {

      let toastMsg = {}
      await axios.post('user/invite', this.invitation).then((res) => {
        toastMsg = {
          message: 'Invitation sent!',
          duration: 2000,
          position: 'top',
          icon: checkmarkDone,
          color: 'success'
        }
        Object.keys(this.invitation).map(key => {
          this.invitation[key] = null
        })
        this.invitationUrl = res.data.invite_url
        this.user.invites.push(res.data.invitation)
      }).catch((error) => {
        toastMsg = {
          message: 'Something went wrong',
          duration: 2000,
          position: 'top',
          color: 'danger'
        }
        this.errors = error.response.data
      })

      let toast = await toastController.create(toastMsg);
      return await toast.present()

    }
  },
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonText,
    IonTextarea,
    IonInput,
    IonSelect,
    IonButton,
    VForm: V.Form,
    VField: V.Field,
    VErrorMessage: V.ErrorMessage,
    VueGoodTable,
    //ProjectInviteRole
  },
});
</script>
