<template>
  <ion-app>
    <ion-split-pane content-id="main-content" v-if="authenticated">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header class="ion-margin-bottom">
              <ion-img @click="gotoHomepage" :src="logoImg"></ion-img>
            </ion-list-header>

            <ion-item v-if="user" class="ion-margin-bottom">
              <ion-text>{{ user.name }}</ion-text>
              <ion-badge slot="end">{{ userRole }}</ion-badge>
            </ion-item>

            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">

              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none"
                        detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}
                  <ion-badge color="secondary" v-if="p.role">{{ p.role.toUpperCase() }}</ion-badge>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-item @click.prevent="signOut" v-if="authenticated" lines="none" detail="false" class="hydrated">
              <ion-icon slot="start" :ios="exitOutline" :md="exitSharp"></ion-icon>
              <ion-label>Log out</ion-label>
            </ion-item>
          </ion-list>

        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
    <ion-router-outlet v-else></ion-router-outlet>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonImg,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonBadge
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {useRoute} from 'vue-router';
import {
  homeOutline,
  homeSharp,
  personOutline,
  personSharp,
  settingsOutline,
  settingsSharp,
  peopleOutline,
  peopleSharp,
  personAddOutline,
  personAddSharp,
  briefcaseOutline,
  briefcaseSharp,
  exitOutline,
  exitSharp,
  //personCircle,
  businessOutline,
  businessSharp
} from 'ionicons/icons';
import {
  mapGetters,
  mapActions
} from 'vuex'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonBadge,
    IonImg
  },
  data() {
    return {
      logoImg: require('@/assets/img/logo.svg')
    }
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Dashboard',
        url: '/',
        iosIcon: homeOutline,
        mdIcon: homeSharp
      },
      {
        title: 'Profile',
        url: '/profile',
        iosIcon: personOutline,
        mdIcon: personSharp
      },
      {
        title: 'Account settings',
        url: '/account-settings',
        iosIcon: settingsOutline,
        mdIcon: settingsSharp
      },
      {
        title: 'Experts',
        url: '/experts',
        iosIcon: peopleOutline,
        mdIcon: peopleSharp
      },
      {
        title: 'Projects',
        url: '/projects',
        iosIcon: briefcaseOutline,
        mdIcon: briefcaseSharp
      },
      {
        title: 'Organizations',
        url: '/organizations',
        iosIcon: businessOutline,
        mdIcon: businessSharp
      },
      {
        title: 'Invite expert',
        url: '/inviteuser',
        iosIcon: personAddOutline,
        mdIcon: personAddSharp,
      },


    ];

    /*if (!appPages.length ) {
      appPages.push({
        title: 'Users',
        url: '/users',
        iosIcon: personCircle,
        mdIcon: personCircle,
        role: 'admin'
      })
    } */

    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      personOutline,
      personSharp,
      settingsOutline,
      settingsSharp,
      peopleOutline,
      peopleSharp,
      personAddOutline,
      personAddSharp,
      briefcaseOutline,
      briefcaseSharp,
      exitOutline,
      exitSharp,
      isSelected: (url) => url === route.path ? 'selected' : ''

    }
  },
  mounted() {

    // accessToken, vuexy-skin, refreshToken,  
    //document.body.classList.toggle('dark');
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    userRole() {
      if (this.user) {
        return this.user.roles ? this.user.roles[0].toUpperCase() : 'EXPERT'
      }
      return ''

    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    signOut() {
      this.logout().then(() => {
        this.$router.go({
          name: 'Login'
        })
      })
    },
    gotoHomepage() {
      this.$router.replace('/')
    }
  }
});
</script>

<style scoped>

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}


ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}


ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}


ion-item.selected {
  --color: var(--ion-color-primary);
  font-weight: 600;
}

ion-item:hover {
  --color: var(--ion-color-primary);
  cursor: pointer;
}

</style>
