import axios from 'axios'

export default {
	namespaced: true,

	state: {
		token: null,
		user: null
	},
    getters: {
        authenticated(state) {
            return state.token && state.user
        },
        user(state) {
            return state.user
        },
        organizations(state) {
            return state.user ? state.user.organizations : null
        },
        skills(state) {
            return state.user ? state.user.skills : null
        },
        invitations(state) {
            return state.user ? state.user.invitations : null
        }
    },
	mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, data) {
            state.user = data
        },
        SET_PROFILE(state, profile) {
            state.user.profile = profile
        },
        SET_SKILLS(state, skills) {
            state.user.skills = skills
        },
        SET_LANGUAGES(state, languages) {
            state.user.languages = languages
        },
        SET_ORGANIZATIONS(state, organizations) {
            state.user.organizations = organizations
        },
        ADD_ORGANIZATION(state, organization) {
            state.user.organizations.push(organization)
        },
        REMOVE_ORGANIZATION(state, id) {
            const idx = state.user.organizations.findIndex(organization => organization.id == id)
            state.user.organizations.splice(idx, 1);
        },
        SET_INVITES(state, invites) {
            state.user.invites = invites
        },
    },
	actions: {
		async login({ dispatch }, credentials) {
			let response = await axios.post('user/login', credentials)
            if (response.data.accessToken) {
                return dispatch('attempt', response.data.accessToken)
            }
            return response.data
		},

        async socialLogin({dispatch}, token) {
            return dispatch('attempt', token)
        },

        async attempt({ commit, state }, token) {
            if(token) {
                commit('SET_TOKEN', token)
            }

            if(!state.token) {
                return
            }

            try {
                let response = await axios.get('user')
                commit('SET_USER', response.data)
            } catch(e) {
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
            }
        },

        logout({ commit }) {
            return axios.post('user/logout').then(() => {
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
            })
        },

        async loadProfile({commit}) {
            let response = await axios.get('profile')
            commit('SET_PROFILE', response.data.profile)
            commit('SET_SKILLS', response.data.skills)
            commit('SET_LANGUAGES', response.data.languages)
            commit('SET_ORGANIZATIONS', response.data.organizations)
        },

        async loadUserOrganizations({commit}) {
            let response = await axios.get('user/organization')
            commit('SET_ORGANIZATIONS', response.data)
        },

        async addOrganization(context, organization) {
            let response = await axios.post('organizations', organization)
            context.commit('SET_ORGANIZATIONS', response.data)
        },

        async saveOrganization(_, organization) {
            return await axios.put(`organization/${organization.id}`, organization)
        },

        async loadUserInvites({commit}) {
            let response = await axios.get('user/invites')
            commit('SET_INVITES', response.data)
        },

        /*acceptInvite(inviteToken) {
            return axios.post('acceptinvite', {token:inviteToken}).then(() => {
                alert('OK')
            }).catch((error) => {
                console.log('This invitation is invalid', error)
            })
        }, */

        saveProfile({commit}, profile) {
            return axios.put('profile', profile).then(() => {
                commit('SET_PROFILE', profile)
            }).catch((error) => {
                console.log(error)
            })

        },
        saveAccount(_, userinfo) {

            return axios.put('profile/update', userinfo).then(() => {
                //commit('SET_PROFILE', userinfo)
            })

        }


	}
}
