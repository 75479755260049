<template>
  <base-layout page-title="Dashboard">

    <ion-grid v-if="currentUser">
      <ion-row class="ion-align-items-center">
        <ion-col size-xs="12" size-md="4" offset-md="1">

          <h1>Hello <strong>{{ currentUser.name }}</strong>!</h1>

          <p>Welcome to <strong>Kratt</strong> - a place where you can find the experts for your project or participate in others' projects as an expert </p>
          <p>Intelligent platform for digital professionals to create successful project teams.</p>
          <h3 class="ion-margin-bottom">Choose your next action</h3>

          <div>
            <ion-button routerLink='/profile' color="primary" expand="block" class="ion-margin-bottom">View your expert profile</ion-button>

            <ion-button routerLink='/experts' fill="outline" expand="block" class="ion-margin-bottom">Find expert for your projects.</ion-button>

            <ion-button routerLink='/projects' fill="outline" expand="block" class="ion-margin-bottom">View your projects</ion-button>
          </div>

        </ion-col>
        <ion-col size-xs="12" size-md="6">
          <ion-img :src="loginImg"></ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-spinner name="bubbles" v-else></ion-spinner>
  </base-layout>
</template>

<script>
import {
  defineComponent
} from 'vue';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSpinner,
    IonButton
} from '@ionic/vue';

export default defineComponent({
  name: 'HomePage',
  data() {
    return {
      loginImg: require('@/assets/img/login.svg'),
    }
  },
  methods: {

  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/user']
    }
  },
  components: {
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonImg
  },
});
</script>

<style scoped>
ion-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
