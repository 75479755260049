<template>
  <div>

    <!-- approve/delete project invitations -->
    <div v-if="projectOwnerInvitations.length">
      <h3 class="ion-padding-horizontal">Manage project invitations</h3>

        <template v-for="(invitation,idx) in projectOwnerInvitations" :key="idx">
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle :color="invitation.status == 'accepted' ? 'primary' : 'warning'">#{{ invitation.status.toUpperCase() }}</ion-card-subtitle>
              <ion-card-title v-if="invitation.invitationuser">{{ invitation.role }}- {{ invitation.invitationuser.name}}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div v-if="invitation.description">
                {{ invitation.description }}
              </div>

              <div v-if="invitation.status == 'accepted' && 1 == 0">
                <ion-card-subtitle class="ion-text-lg-capitalize">* Confirm and assign project role</ion-card-subtitle>
                <ion-chip v-for="role in project.team" :key="role.role"
                          :color="(role.role == invitation.role) ? 'success' : 'dark' "
                >
                  {{ role.role }}
                </ion-chip>
                <p>* If role is not listed, you can add it below under project roles</p>
              </div>

              <div>
                <ion-button
                    v-if="invitation.status == 'accepted'"
                    @click="updateInviteStatus(invitation.id, 'approve')">
                  Approve
                </ion-button>
                <ion-button color="danger" @click="deleteInvite(invitation.id)">Delete</ion-button>
              </div>

            </ion-card-content>



          </ion-card>
        </template>


    </div>

    <!-- if active project invitations -->
    <div v-if="project">
      <ion-card v-if="!inviteResponded && projectInvitation" >
        <ion-card-header color="light">
          <h5>You have been invited to join {{ projectInvitation.project.name }} as a
            <strong>{{ projectInvitation.role }}</strong>
          </h5>
        </ion-card-header>
        <ion-card-content>
            <ul>
              <li>The above information is general project information provided for reference. The information provided in
                project page is not specifically only about your work unless it is tiny project with only one person team.
              </li>
              <li>You should agree all the details of your specific part and role, timelines and budgets in this project
                with the person who invited you before indicating willingness to participate in the project.
              </li>
              <li>Any legal agreements, contracts and payment terms should be agreed outside KRATT platform.</li>
              <li>When you have all the necessary information and agreements and have made a decision to participate in the
                project then indicate so with clicking "Accept".
              </li>
              <li>When you have made a decision not to participate in the project then click "Reject"</li>
              <li>After accepting project the person who invited you into project team will have to make final approval and
                then you are officially part of the team!
              </li>
            </ul>
            <p>Do you agree to participate in this project?</p>
            <div class="d-flex mb-1">
              <ion-button color="success" @click="updateInviteStatus(projectInvitation.id, 'accept')">ACCEPT</ion-button>
              <ion-button color="danger" @click="updateInviteStatus(projectInvitation.id, 'reject')">REJECT</ion-button>
            </div>

        </ion-card-content>
      </ion-card>
    </div>
    <div v-else>
      <ion-card v-if="invitations.length">
        <ion-card-header color="primary">
          <h2>Project invitations sent to you</h2>
          <p>Please let the project owners know your response</p>
        </ion-card-header>
        <ion-card-content>
          <ion-item v-for="invitation in invitations" :key="invitation.id" >
            <template v-if="invitation.project">
              <ion-text>
                <p>You have been invited to join
                  "
                  <router-link :to="`/projects/${invitation.project.hash}`">{{ invitation.project.name }}</router-link>
                  "
                  project as a <strong>{{ invitation.role }}</strong>
                </p>
              </ion-text>
              <ion-button slot="end" size="small" color="medium" :router-link="`/projects/${invitation.project.hash}`">
                details
              </ion-button>
            </template>
            <ion-text v-else>
              <p>It seems the project you have been invited, has been deleted</p>
            </ion-text>

          </ion-item>
        </ion-card-content>
      </ion-card>
    </div>


    </div>


</template>

<script>
import {
  IonButton, toastController, IonCard, IonCardContent, alertController, IonChip
} from '@ionic/vue';
import axios from "axios";
import {checkmarkOutline} from "ionicons/icons";

export default {
  name: 'ProjectInvitations',
  props: {
    project: Object,
  },

  data() {
    return {
      inviteResponded: false
    }
  },
  created() {
    this.$store.dispatch('projects/loadProjectInvites')
  },
  computed: {
    invitations() {
      return this.$store.getters['projects/projectInvites']
    },
    projectInvitation() {
      if (this.project) {
        if (this.invitations) {
          let selected = this.invitations.filter(item => item.project.hash === this.project.hash)
          return selected ? JSON.parse(JSON.stringify(selected))[0] : false
        }
      }
      return false
    },
    projectOwnerInvitations() {
      return this.project ? this.project.invitations : []
    }
  },
  methods: {
    async updateInviteStatus(invitation_id, status) {
      let toastMsg = ''
      await axios.post('projectinvite/' + status, {
        invitation_id,
        status
      }).then(() => {
        this.inviteResponded = true
        //this.$store.commit('projects/SET_PROJECTS', res.data.projects)
        toastMsg = {
          message: 'Invitation response sent!',
          duration: 2000,
          position: 'top',
          icon: checkmarkOutline,
          color: 'success'
        }
      }).catch(() => {

        toastMsg = {
          header: 'Error',
          message: 'Something went wrong',
          duration: 3000,
          position: 'top',
          color: 'danger'
        }
      })

      let toast = await toastController.create(toastMsg);
      return await toast.present()
    },

    async deleteInvite(id) {
      const alert = await alertController.create({
        header: 'Delete invitation?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {

            },
          },
          {
            text: 'Confirm',
            role: 'confirm',
            handler: () => {

              axios.post('projectinvite/delete', {id: id}).then(() => {
                const idx = this.project.invitations.findIndex(inv => inv.id == id)
                this.projectOwnerInvitations.splice(idx, 1)
              })
            },
          },
        ],
      });

      await alert.present();


    }
  },
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonChip
  }
}
</script>

