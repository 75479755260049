<template>
  <div>
    <ion-item style="display:none;">
      <input type="file" ref="uploadBtn" @change="upload">
    </ion-item>
    <v-form @submit="saveProfile">

      <ion-item-group>
        <ion-item-divider>
          <ion-label>General info</ion-label>
        </ion-item-divider>


        <ion-item>
          <v-field name="oneliner" v-slot="{field}" v-model="userData.profile.oneliner" rules="required">
            <ion-label position="floating">* One liner</ion-label>
            <ion-input type="text" v-bind="field"></ion-input>
          </v-field>
          <v-error-message name="oneliner" class="error"/>
        </ion-item>

        <div class="ion-margin-top">
          <QuillEditor placeholder="About you" contentType="html" v-model:content="userData.profile.bio"/>
        </div>

      </ion-item-group>

      <ion-item-group class="ion-margin-top">
        <ion-item-divider>
          <ion-label>Profile status and availability</ion-label>
        </ion-item-divider>

        <ion-item>
          <v-field name="status" v-model="userData.profile.status" v-slot="{field}">
            <ion-label>Profile active</ion-label>
            <ion-toggle color="success" value="active" :checked="userData.profile.status == 'active'" v-bind="field">
            </ion-toggle>
          </v-field>

        </ion-item>
        <ion-radio-group v-model="availability.availability">
          <ion-list-header>
            <ion-label>Availability</ion-label>
          </ion-list-header>

          <ion-item v-for="(text, idx) in availabilityTexts" :key="idx">
            <ion-label>{{ text }}</ion-label>
            <ion-radio slot="start" :value="idx"></ion-radio>
          </ion-item>
        </ion-radio-group>

        <ion-item>
          <ion-textarea placeholder="Additional availability comment"
                        v-model="availability.comment"></ion-textarea>
        </ion-item>

      </ion-item-group>

      <ion-item-group class="ion-margin-top">
        <ion-item-divider>
          <ion-label>My contacts</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-text color="dark">
            <p>How do you prefer others to contact you?<br>
              Drag and drop preferred contact channels</p>
          </ion-text>
        </ion-item>

        <draggable :list="contacts" group="contacts" @start="drag=true" handle=".move"
                   @end="drag=false" item-key="id">
          <template #item="{element}">
            <div>
              <ion-button type="button" color="medium" size="small" class="move">
                <ion-icon :icon="swapVerticalOutline"></ion-icon>
              </ion-button>

              <ion-item>
                <ion-input type="text" v-model="element.channel" placeholder="channel"
                           :disabled="element.channel === 'email' || element.channel === 'phone'"></ion-input>
                <ion-input type="text" v-model="element.contact" placeholder="contact"></ion-input>
                <ion-button
                    type="button"
                    size="small"
                    color="danger"
                    @click="removeChannel(element.channel)"
                    :disabled="element.channel == 'email' || element.channel == 'phone'"
                >
                  <ion-icon :icon="trashOutline"></ion-icon>

                </ion-button>

              </ion-item>
            </div>
          </template>
          <template #footer>
            <ion-button type="button" size="small" color="dark" @click="addChannel">Add contact channel</ion-button>
          </template>
        </draggable>

      </ion-item-group>

      <ion-item-group class="ion-margin-top">
        <ion-item-divider>
          <ion-label>Other info</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label position="floating">Default hourly rate</ion-label>
          <ion-input type="text" v-model="userData.profile.hourly_rate"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Website</ion-label>
          <ion-input type="url" v-model="userData.profile.website"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Birthday</ion-label>
          <ion-input type="date" v-model="userData.profile.birthday"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Portfolio link</ion-label>
          <ion-input type="url" v-model="userData.profile.portfolio_link"></ion-input>
        </ion-item>

      </ion-item-group>

      <ion-item-group class="ion-margin-top">
        <ion-item-divider>
          <ion-label>Social links</ion-label>
        </ion-item-divider>
        <ion-item v-for="(social, idx) in socials" :key="idx">
          <ion-label position="floating">{{ social }}</ion-label>
          <ion-input type="url" v-model="userData.profile[idx]"></ion-input>
        </ion-item>
      </ion-item-group>

      <ion-button type="sumbit" color="success">
        Save profile
      </ion-button>
    </v-form>
  </div>
</template>

<script>
import {
  IonItem,
  IonListHeader,
  IonText,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonToggle,
  IonItemGroup,
  IonItemDivider,
  toastController,
    IonIcon,
} from '@ionic/vue';

import {
  trashOutline,
    moveOutline,
    swapVerticalOutline,
} from 'ionicons/icons';


import draggable from 'vuedraggable'
import axios from "axios";

import {
  defineComponent
} from 'vue';

import * as V from 'vee-validate/dist/vee-validate'
import {
  defineRule
} from 'vee-validate/dist/vee-validate'
import {
  email,
  required
} from '@vee-validate/rules'


export default defineComponent({
  name: 'ProfileForm',
  emits: [],
  props: {
    user: Object,
  },
  setup() {
    defineRule('email', email)
    defineRule('required', required)
    return {
      email,
      required
    }
  },
  data() {
    return {
      trashOutline,
      moveOutline,
      swapVerticalOutline,
      availabilityTexts: {
        no: 'No projects please!',
        maybe: 'Might consider some interesting project',
        yes: 'Actively seeking projects'
      },
      socials: {
        linkedin: 'LinkedIn',
        github: 'Github'
      },
      contacts: []
    }

  },
  created() {

    this.contacts = this.user.profile.contacts ?? []

    if (this.contacts.findIndex(el => el.channel === 'email') < 0) {
      this.contacts.push({channel: 'email', contact: ''});
    }

    if (this.contacts.findIndex(el => el.channel === 'phone') < 0) {
      this.contacts.push({channel: 'phone', contact: ''});
    }
  },
  computed: {
    userData() {
      return this.user
    },
    availability() {
      return this.user.profile.availability ? this.user.profile.availability : {
        availability : null,
        comment: null
      }
    }
  },
  methods: {
    addChannel() {
      this.contacts.push({
        channel: '',
        contact: ''
      })
    },
    removeChannel: function (channel) {
      let idx = this.contacts.findIndex(contact => contact.channel === channel);
      this.contacts.splice(idx, 1)
    },
    async saveProfile() {
      this.userData.profile.contacts = this.contacts
      this.userData.profile.availability = this.availability
      let userStatus = this.userData.profile.status ? 'active' : 'inactive'
      this.userData.profile.status = userStatus
      this.$store.dispatch('auth/saveProfile', this.userData.profile)

      const toastMsg = {
        message: 'Profile data saved',
        duration: 3000,
        position: 'top',
        color: 'success'
      }

      let toast = await toastController.create(toastMsg);
      return await toast.present()
    },
    async upload() {
      //console.log(event.target.files)
      let files = this.$refs.uploadBtn.files
      let formData = new FormData()
      formData.append('avatar', files[0]);

      console.log(files[0]);
      await axios.post('upload' , formData).then((res) => console.log(res.data));
    }
  },
  components: {
    IonItem,
    IonListHeader,
    IonLabel,
    IonText,
    IonInput,
    IonTextarea,
    IonRadioGroup,
    IonRadio,
    IonButton,
    draggable,
    IonToggle,
    IonItemGroup,
    IonItemDivider,
    VForm: V.Form,
    VField: V.Field,
    VErrorMessage: V.ErrorMessage,
    IonIcon
  },
});
</script>
<style scoped>
.ql-editor {
  min-height: 140px;
}
</style>