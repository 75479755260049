<template>
  <ion-modal ref="modal" trigger="organization_form_modal" @willDismiss="onWillDismiss">
    <ion-header>
      <ion-toolbar>
        <ion-title>Add organization</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="cancel">CLOSE</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <OrganizationForm @save-organization="saveOrganization" />
    </ion-content>
  </ion-modal>

</template>

<script>

import {
  defineComponent,
} from 'vue';

import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButton,

} from '@ionic/vue';
import OrganizationForm from "@/components/organizations/OrganizationForm";

export default defineComponent({
  name: 'OrganizationAdd',
  components: {
    OrganizationForm,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButton,
  },
  props: {
    organization: Object
  },

  data() {
    return {}
  },
  methods: {
    saveOrganization(data) {
      this.$store.dispatch('auth/addOrganization', data)
      this.$refs.modal.$el.dismiss(name, 'confirm');
    },
    cancel() {
      this.$refs.modal.$el.dismiss(null, 'cancel');
    },
    onWillDismiss(ev) {
      if (ev.detail.role === 'confirm') {
        console.log('confirm')
      }
    }
  }
});
</script>
<style scoped>
ion-content {
  height: calc(100% - 56px);
  overflow: auto;
}

</style>
