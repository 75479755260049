import { createStore } from 'vuex'
import auth from './auth'
import experts from './experts'
import projects from './projects'

export const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {

  },
  modules: {
      auth,
      experts,
      projects
  }
})
