<template>
  <base-layout page-title="My organizations">
    <ProfileOrganizations v-if="user" :organizations="user.organizations" />
  </base-layout>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {mapGetters} from "vuex";
import ProfileOrganizations from "@/components/user/ProfileOrganizations"

export default defineComponent({
  name: 'OrganizationsList',

  data() {
    return {

    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {

  },
  components: {
    ProfileOrganizations
  },

})
</script>

<style lang="css" scoped>
.bottom-right {
  bottom: 40px;
  right: 40px;
}
</style>
