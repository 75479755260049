<template>
<ion-page>
	<ion-header :translucent="true">
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
			</ion-buttons>
			<ion-title>{{ pageTitle }}</ion-title>
			<ion-buttons slot="end">
        <ion-button id="trigger-notifications" v-if="1 == 0">
          <ion-badge color="danger">{{ invitations.length }}</ion-badge>
          <ion-icon :icon="notificationsSharp"></ion-icon>
        </ion-button>
        <ion-button @click="switchTheme()" v-if="1 == 0">
          <ion-icon v-if="theme == 'light'" :icon="moonSharp"></ion-icon>
          <ion-icon v-if="theme == 'dark'" :icon="sunnySharp"></ion-icon>
        </ion-button>

        <ion-popover trigger="trigger-notifications">
          <ion-content>
            <ion-list>
              <ion-list-header color="light">
                <ion-label>Notifications</ion-label>

              </ion-list-header>
              <ion-item v-for="invitation in invitations" :key="invitation.id">

                <ion-label>
                  <p>#Project invitation</p>
                  <h2 v-if="invitation.project">{{ invitation.project.name }}</h2>
                  <ion-text color="primary">{{ invitation.role }}</ion-text>
                </ion-label>


              </ion-item>
              <ion-button expand="full">View all</ion-button>
            </ion-list>
          </ion-content>
        </ion-popover>

        <ion-menu-button></ion-menu-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>


	<ion-content :fullscreen="false">
    <slot />
	</ion-content>
</ion-page>
</template>

<script>
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonBackButton,
	IonButtons,
    IonButton,
  IonMenuButton,
    IonBadge,
  IonPopover,
    IonList,
    IonListHeader,
    IonLabel,
    IonText,
    IonIcon,
  IonItem
} from '@ionic/vue';
import {
  moonSharp,
    sunnySharp,
    notificationsSharp,
} from 'ionicons/icons';

import {
  mapGetters,
} from 'vuex'

export default {
	props: ['pageTitle', 'pageDefaultBackLink'],
  data() {
    return {
      sunnySharp,
      moonSharp,
      notificationsSharp,
      theme: 'light'
    }
  },
  created() {
    /*if (this.invitations === false && this.authenticated) {
      this.$store.dispatch('projects/loadProjectInvites')
    }*/

  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    invitations() {
      return this.$store.getters['projects/projectInvites']
    }
  },

  methods: {
    switchTheme() {
      this.theme = (this.theme == 'dark') ? 'light' : 'dark'
      document.body.classList.toggle('dark');
    }
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonBackButton,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonBadge,
    IonPopover,
    IonList,
    IonListHeader,
    IonLabel,
    IonText,
    IonIcon,
    IonItem
  },

}
</script>

<style lang="css" scoped>
ion-badge {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9;
  font-size: 10px;
  z-index: 1;
}

ion-badge ~ ion-icon {
    margin-right: 8px;

}
ion-list {
  padding: 0;
}
</style>
