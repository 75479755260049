<template>
  <div>
    <ion-item color="danger" v-if="selectedSkills.length != Object.keys(skillRatings).length">
      You have unsaved skill ratings. Please add ratings to all skills and save.
    </ion-item>
    <form @submit.prevent="saveSkills">

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Skills</ion-label>
        </ion-item-divider>
      </ion-item-group>

      <v-select
          multiple taggable push-tags
          placeholder="Add skill"
          :close-on-select="false"
          value="id"
          label="title"
          v-model="selectedSkills"
          :options="allSkills"
      ></v-select>

      <div v-if="selectedSkills.length > 0">
        <ion-item lines="none">
          <h3>Please *rate your skills</h3>
        </ion-item>
        <ion-item>
          <p>*How useful are you with this skill as an expert?</p>
        </ion-item>

        <ion-item v-for="skill in selectedSkills" :key="skill.id">
          <strong v-bind:class="{ 'error' : !skillRatings[skill.title]}">{{ skill.title }}</strong>
          <star-rating animate = true slot="end" star-size=24 v-model:rating="skillRatings[skill.title]"/>
        </ion-item>

        <div class="ion-text-right ion-margin-top" >
          <ion-button type="sumbit" color="success">
            Save skills
          </ion-button>
        </div>
      </div>
      <div v-else>
        <p>Start selecting your skills</p>
      </div>



    </form>
  </div>
</template>

<script>
import {
  IonItemGroup,
  IonItemDivider,
  IonItem,
  toastController
} from '@ionic/vue';

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import StarRating from 'vue-star-rating'

import {
  defineComponent
} from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'ProfileSkills',
  emits: [],
  props: {
    skills: Object,
  },
  data() {
    return {
      allSkills: this.skills,
      selectedSkills: [],
      skillRatings: {},
    }

  },
  created() {

    this.selectedSkills = this.allSkills.filter((skill) => {
      return skill.pivot
    })

    let skillRatings = this.skillRatings
    this.selectedSkills.map(function(rating) {
      skillRatings[rating.title] = rating.pivot ? rating.pivot.level : 0
    })
  },

  watch: {
    selectedSkills(val) {
      let skillRatings = {};
      JSON.parse(JSON.stringify(val)).filter((skill) => {

        if (this.skillRatings[skill.title]) {
          skillRatings[skill.title] = this.skillRatings[skill.title]
          return true
        }
        return false
      })

      this.skillRatings = skillRatings
    }
  },
  methods: {
    async saveSkills() {

      let toastMsg = {}

      if (this.selectedSkills.length == Object.keys(this.skillRatings).length) {
        let info = {
          skills: this.selectedSkills,
          ratings: this.skillRatings
        }

        await axios.post('profile/skills', info)
        toastMsg = {
          message: 'Skills are saved',
          duration: 3000,
          position: 'top',
          color: 'success'
        };

        //this.$store.commit('auth/SET_SKILLS', this.selectedSkills)

      } else {
        toastMsg = {
          message: 'Please rate all skills!',
          duration: 3000,
          position: 'top',
          color: 'danger'
        }
      }

      let toast = await toastController.create(toastMsg);

      return await toast.present()
    }
  },
  components: {
    vSelect,
    IonItemGroup,
    IonItemDivider,
    IonItem,
    StarRating
  },
});
</script>
<style>
.vue-star-rating-rating-text {
  display: none;
}
</style>