<template>
  <div>
    <form @submit.prevent="saveLanguages">

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Languages</ion-label>
        </ion-item-divider>
      </ion-item-group>


      <p>Select which languages do you speak</p>

      <v-select
          multiple taggable push-tags
          placeholder="Add language"
          value="id"
          label="title"
          v-model="selectedLanguages"
          :options="allLanguages"
      ></v-select>

      <div v-if="selectedLanguages.length > 0">

        <div class="ion-text-right ion-margin-top" >
          <ion-button type="sumbit" color="success">
            Save languages
          </ion-button>
        </div>
      </div>
      <div v-else>
        <p>Start selecting your languages</p>
      </div>

    </form>
  </div>
</template>

<script>
import {
  IonItemGroup,
  IonItemDivider,
  toastController,
    IonLabel,
    IonButton
} from '@ionic/vue';

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'


import {
  defineComponent
} from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'ProfileLanguages',
  emits: [],
  props: {
    languages: Object
  },
  data() {
    return {
      allLanguages: this.languages,
      selectedLanguages: [],
    }

  },
  created() {

      this.selectedLanguages = this.languages.filter((language) => {
        return language.pivot
      })


  },


  methods: {
    async saveLanguages() {

      let toastMsg = {}

      let info = {
        language: this.selectedLanguages,
      }
      await axios.post('profile/languages', info).then(() => {
        toastMsg = {
          message: 'Languages are saved',
          duration: 3000,
          position: 'top',
          color: 'success'
        };
      }).catch(()=> {
        toastMsg = {
          message: 'Languages not saved',
          duration: 3000,
          position: 'top',
          color: 'danger'
        };
      })


      let toast = await toastController.create(toastMsg);

      return await toast.present()
    }
  },
  components: {
    vSelect,
    IonItemGroup,
    IonItemDivider,
    IonLabel,
    IonButton
  },
});
</script>