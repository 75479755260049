<template>
<base-layout page-title="My Profile">
	<ion-grid>
		<ion-row>
			<ion-col size-xs="12" size-xxl="8">
				<ion-segment @ionChange="tabChanged($event)" :value="currentTab">
					<ion-segment-button value="general">
						<ion-icon :icon="personCircle"></ion-icon>
						<ion-label>General</ion-label>
					</ion-segment-button>
					<ion-segment-button value="skills">
						<ion-icon :icon="colorWandOutline"></ion-icon>
						<ion-label>Skills</ion-label>
					</ion-segment-button>
				</ion-segment>

				<div v-if="currentTab == 'general'" class="ion-padding-top">
					<ProfileForm :user="user" />
				</div>

        <div v-if="currentTab == 'skills'" class="ion-padding-top">
					<ProfileSkills :skills="user.skills" />
          <ProfileLanguages :languages="user.languages"/>
				</div>

			</ion-col>
		</ion-row>
	</ion-grid>
</base-layout>
</template>

<script>
import {
	defineComponent
} from 'vue';

import {
	IonGrid,
	IonRow,
	IonCol,
	IonSegment,
	IonSegmentButton,
	IonIcon,
    IonLabel
} from '@ionic/vue';

import {
	colorWandOutline,
	personCircle,
    people,
} from 'ionicons/icons';

import {
	mapGetters,
	mapActions
} from 'vuex'

import ProfileForm from '@/components/user/ProfileForm.vue'
import ProfileSkills from '@/components/user/ProfileSkills.vue'
import ProfileLanguages from "@/components/user/ProfileLanguages";

export default defineComponent({
	name: 'ProfilePage',
	data() {
		return {
      colorWandOutline,
      personCircle,
      people,
			currentTab: 'general',
		}
	},
	created() {
		//this.loadProfile()
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		})
	},
	methods: {
		...mapActions({
			loadProfile: 'auth/loadProfile'
		}),
		tabChanged(ev) {
      if(ev.detail.value == 'general') {
        this.loadProfile()
      }
			this.currentTab = ev.detail.value
		},
	},
	components: {
    ProfileLanguages,
		ProfileForm,
    ProfileSkills,
		IonGrid,
		IonRow,
		IonCol,
		IonSegment,
		IonSegmentButton,
		IonIcon,
    IonLabel
	},
});
</script>
