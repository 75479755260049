<template>
  <base-layout page-title="My projects">

    <ProjectInvitations />
    <!--<ProjectInvitation />-->

    <ion-list v-if="Object.keys(userProjects).length">
      <ion-item
          v-for="project in userProjects"
          :router-link="`/projects/${project.hash}`"
          :key="project.id"
      >
        <ion-button slot="end">VIEW</ion-button>

        {{ project.name }}

      </ion-item>
    </ion-list>
    <ion-card class="ion-padding ion-margin-top" v-else>
      <ion-card-header>
        <ion-card-subtitle>
          You haven't added any project yet! Click the add button to start
        </ion-card-subtitle>
        <ion-card-title>
          Create your first project!
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>"It all starts with a tiny, stupid idea, then one thing leads to another, and suddenly, you find something amazing: yourself."</p>
        <p><small>― Richie Norton, The Power of Starting Something Stupid: How to Crush Fear, Make Dreams Happen, and Live without Regret</small></p>

      </ion-card-content>

    </ion-card>


    <ion-fab slot="fixed" class="bottom-right">
      <ion-fab-button :router-link="`/projects/add`">
        <ion-icon :icon="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </base-layout>
</template>

<script>
import {
  defineComponent
} from 'vue';

import {
  IonList,
  IonItem,
  IonFab,
  IonFabButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
    IonButton
} from '@ionic/vue';

import {addOutline} from 'ionicons/icons';
import ProjectInvitations from "@/components/projects/ProjectInvitations";

export default defineComponent({
  name: 'ProjectsList',
  components: {
    ProjectInvitations,
    IonList,
    IonItem,
    IonFab,
    IonFabButton,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton
  },
  data() {
    return {
      addOutline,
    }
  },
  mounted() {
    if (this.userProjects.length < 1) {
      this.$store.dispatch('projects/loadProjects')
    }

  },
  computed: {
    userProjects() {
      return this.$store.getters['projects/projects']
    }
  },
  methods: {
  }
})
</script>

<style lang="css" scoped>
.bottom-right {
  bottom: 40px;
  right: 40px;
}
ion-item:hover {
  --color: var(--ion-color-primary);
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
  cursor: pointer;
}
</style>
