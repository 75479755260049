import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import ExpertsList from '../views/experts/ExpertsList.vue'
import ExpertInvite from '../views/experts/ExpertInvite.vue'
import ProjectsList from '../views/projects/ProjectsList.vue'
import OrganizationsList from '../views/organizations/OrganizationsList.vue'
import ProfilePage from '../views/user/ProfilePage.vue'

import {store} from '../store/index'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/authentication/ForgotPasswordPage.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/authentication/ResetPasswordPage.vue'),
  },
  {
    path: '/callback/:token',
    name: 'callback',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/register/:token?',
    name: 'Register',
    component: () => import('@/views/authentication/RegisterPage.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage
  },
  {
      path: '/account-settings',
      name: 'AccountSettings',
      component: () => import('@/views/user/AccountSettings.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/UsersList.vue'),
    meta: {
      role: 'admin',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: OrganizationsList

  },
  {
    path: '/experts',
    name: 'Experts',
    component: ExpertsList,
    /*children: {
        path: 'vi',
        component: null
    }*/
  },
  {
    path: '/experts/:username',
    name: "ExpertDetail",
    component: () => import('../views/experts/ExpertDetail.vue')
  },
  {
    path: '/inviteuser',
    component: ExpertInvite,
    meta: {
      role: 'agency',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/skillsmapping',
    name: 'Skills mapping',
    component: () => import('@/views/SkillsMapping.vue'),
    meta: {
      role: 'admin',
      redirectIfLoggedIn: true,
    },
  },

  // PROJECTS
  {
      path: '/projects',
      component: ProjectsList
  },
  {
      path: '/projects/:hash',
      component: () => import('../views/projects/ProjectDetail.vue')
  },
  {
      path: '/projects/add',
      component: () => import('../views/projects/ProjectAdd.vue')
  },
  {
    path: '/projects/:hash/add',
    component: () => import('../views/projects/ProjectCopy.vue')
  },
  /*{
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/error/NotFound.vue')
  },
  {
    path: '*',
    redirect: 'not-found',
  },*/


  // DEMO

  {
    path: '/message/:id',
    component: () => import('../views/ViewMessagePage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {

  if (to.name == 'callback' || to.name =='Register'  || to.name =='ResetPassword' || to.name == 'ForgotPassword') {
    return next()
  }
  const isLoggedIn = store.getters['auth/authenticated']
  //const user = store.getters['auth/user']
  //const isAgency = user ?? user.agency
  //console.log(user)

  if (!isLoggedIn && to.name != 'Login') {
    return next({ name: 'Login' })
  }

  /*const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
}*/

  return next()
})

export default router
