import axios from 'axios'

export default {
	namespaced: true,

	state() {
        return {
            projects: [],
            project_invites: false
        }
	},
    getters: {
        projects(state) {
            return state.projects
        },
        projectInvites(state) {
            return state.project_invites
        }
    },
	mutations: {
        SET_PROJECTS(state, projects) {
            state.projects = projects
        },
        ADD_PROJECT(state, project) {
            state.projects.push(project)
        },
        REMOVE_PROJECT(state, hash) {
            const idx = state.projects.findIndex(project => project.hash == hash)
            state.projects.splice(idx, 1);
        },
        SET_PROJECT_INVITES(state, invites) {
            state.project_invites = invites
        },
        SET_PROJECT(state, projectData) {
            const idx = state.projects.findIndex(project => project.hash == projectData.hash)
            state.projects[idx] = projectData
        }
    },
	actions: {
        async loadProjects({commit}) {
            let response = await axios.get('user/projects')
            commit('SET_PROJECTS', response.data.projects)
        },
        async loadProjectInvites({commit}) {
            let response = await axios.get('user/project_invites')
            commit('SET_PROJECT_INVITES', response.data)
        },
        async loadProject(_, hash) {
            let response = await axios.get(`projects/${hash}`)
            return response.data
        },
        async addProject(context, project) {
            let response = await axios.post('projects', project)

            if (response.data.hash) {
                project.id = response.data.id
                project.hash = response.data.hash
                context.commit('ADD_PROJECT', project)
                return project.hash
            }
        },
        async saveProject(_, project) {
            let response = await axios.put(`projects/${project.id}`, project)

            if (response.data.hash) {
                return response.data.hash
                //context.commit('ADD_PROJECT', project)
            }
        },

	}
}
